/* Leftbar Disappaers for Phone Layout */
@media only screen and (max-width: 1000px) {
  .leftbarContainer {
    display: none;
  }
}

/*------------------------------------------------ Leftbar Conatiner -------------------------------------------------*/
.leftbarContainer {
  flex: 3;
  height: 100%;
  position: sticky;
  top: 90px;
}

/*------------------------------------------------ Leftbar Wrapper -------------------------------------------------*/

.leftbarWrapper {
  /* this code causes the elongation of the whole page  */
  height: 100% !important;
  padding: 20px;
  margin-top: 0px;
  border-radius: 15px;
  background: linear-gradient(75deg, rgb(5, 5, 26) 15%, rgb(79, 95, 114) 100%);
  /* align-content: center; */
}

.leftbarListItem {
  display: flex;
  align-items: center;
  color: white;
  filter: brightness(80%);
  width: 100%;
  transition: 0.3s !important;
  margin: 0px 5px !important;
}

.leftbarListItem:hover {
  filter: brightness(130%);
}

/* the classname of NavLink */
.active {
  /* /* background: rgb(3, 3, 22); */
  background: grey;
  /* border-radius: 25px; */
  color: white;
  filter: brightness(130%);
  /* padding-right: -24px !important; */
}
